import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import { Database } from './services/Database';
import HomePage from './HomePage';
import CarsPage from './CarsPage';
import StreamerPage from './StreamerPage';
import DriversPage from './DriversPage';
import SeasonPage from './SeasonPage';
import RacesPage from './RacesPage';
import RacePage from './RacePage';
import DriverPage from './DriverPage';
import CarPage from './CarPage';
import HostPage from './HostPage';
import NotFoundPage from './NotFoundPage';
import PlayPage from './PlayPage';
import MainPage from './MainPage';
import TwitchPage from './TwitchPage';
import InventoryPage from './InventoryPage';
import StorePage from './StorePage';
import Stats from './StatsPage';
import CodexPage from './CodexPage';
import './App.css';
import GooglePage from './GooglePage';
import Auth0Page from './Auth0Page';
import PrivacyPolicy from './PrivacyPage';
import TermsOfService from './TermsPage';
import LogoutPage from './LogoutPage';

interface AppState {
  initialized: boolean;
};

class App extends React.Component<{}, AppState> {
  constructor(props = {}) {
    super(props);

    this.state = {
      initialized: false
    }
  }

  componentDidMount(): void {
    this.load();

    // document.addEventListener('keydown', (event)=>{
    //   console.log(event.key == 'd');
    //   if(event.key == 'd')
    //     Network.disconnect();
    //   if(event.key == 'c')
    //     Network.connect();
    // });
  }

  async load() {
    Database.init();
    this.setState({initialized: true});
  }

  onUpdate() {
    this.forceUpdate();
  }
  
  render() {
    if(!this.state.initialized) 
      return null;

    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<MainPage />}>
            <Route index element={<HomePage />} />
            <Route path='/codex' element={<CodexPage />} />
            <Route path='/garage' element={<InventoryPage />} />
            <Route path='/races' element={<RacesPage />} />
            <Route path='/race/:id' element={<RacePage />} />
            <Route path='/season' element={<SeasonPage />} />
            <Route path='/drivers' element={<DriversPage />} />
            <Route path='/driver/:id' element={<DriverPage />} />
            <Route path='/cars' element={<CarsPage />} />
            <Route path='/car/:id' element={<CarPage />} />
            <Route path='/streamer' element={<StreamerPage />} />
            <Route path='/play' element={<PlayPage />} />
            <Route path='/host/:id' element={<HostPage />} />
            <Route path='/twitch' element={<TwitchPage onUpdate={()=>this.onUpdate()}/>} />
            <Route path='/stats' element={<Stats/>} />
            <Route path='/store' element={<StorePage />} />
            <Route path="/google" element={<GooglePage onUpdate={()=>this.onUpdate()} />} />
            <Route path="/auth0" element={<Auth0Page onUpdate={()=>this.onUpdate()} />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/logout" element={<LogoutPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;