import React from 'react';
import { Navigate, NavLink } from 'react-router-dom';
import { Races } from './services/Races';
import { Account } from './services/Account';
import './SeasonPage.css';
import { BsCaretDownFill, BsCaretUpFill, BsCheck, BsFillStarFill } from 'react-icons/bs';
import { Database } from './services/Database';
import { PlayFab } from './services/PlayFab';

interface SeasonPageState {
  loading: boolean;
  leaderboard: any;
  stat: string;
  tab: string;
  message: string;
  playerProfile: any;
}

class SeasonPage extends React.Component<{}, SeasonPageState> {
  constructor(props:{}) {
    super(props);
    this.state = {
      loading: false,
      leaderboard: null,
      stat: 'season-points',
      tab: 'quests',
      message: '',
      playerProfile: null
    }
  }

  componentDidMount(): void {
    let tab = sessionStorage.getItem('SeasonTab');
    if(tab) {
      this.setState({tab});
      if(tab == 'stats')
        this.loadStats();
    }

    this.loadSeason();
    this.loadPlayerProfile();
  }

  async loadSeason() {
    await Account.loadSeason();
    this.forceUpdate();
  }

  async loadPlayerProfile() {
    try {
      const profile = await PlayFab.getPlayerProfile();
      this.setState({ playerProfile: profile });
      console.log('Player profile loaded:', profile);
    } catch (error) {
      console.error('Error loading player profile:', error);
    }
  }

  async loadStats() {
    this.setState({loading: true});
    let response = await Races.getSeasonLeaderboard('preseason2a');
    if(response.success)
      this.setState({leaderboard: response.body.leaderboard});
    this.setState({loading: false});
  }

  onTab(tab:string) {
    if(this.state.loading)
      return;

    sessionStorage.setItem('SeasonTab', tab);
    this.setState({tab});

    if(tab == 'stats' && !this.state.leaderboard) {
      this.loadStats();
    }
  }

  onJumpToCurrentLevel() {
    let rewards = Account.getSeasonRewards();
    let playerData = Account.getSeasonPlayerData();
    let reward = Math.min(rewards.length-1, playerData.level)
    document.getElementById('Reward' + reward)?.scrollIntoView({behavior: 'smooth', block: 'center'});
  }

  onJumpToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  async onClaimQuest(questIndex:number) {
    this.setState({message: 'Claiming reward...'});

    setTimeout(async () => {
      let response = await Account.claimQuestReward(questIndex);
      this.setState({message: ''});
      if(response.success)
        this.forceUpdate();
    }, 250);
  }

  async onClaimMilestone(milestoneIndex:number) {
    this.setState({message: 'Claiming reward...'});

    setTimeout(async () => {
      let response = await Account.claimMilestoneReward(milestoneIndex);
      this.setState({message: ''});
      if(response.success)
        this.forceUpdate();
    }, 250);
  }

  async onClaimSeasonReward(level:number) {
    this.setState({message: 'Claiming reward...'});

    setTimeout(async () => {
      let response = await Account.claimSeasonReward(level);
      this.setState({message: ''});
      if(response.success) {
        let rewards = Account.getSeasonRewards();
        Account.addAssetToInventoryCache(rewards[level].asset, 1);
        this.forceUpdate();
      }
    }, 250);
  }

  renderLeaderboard() {
    let divs = [];

    if(this.state.loading) 
      divs.push(<div key={0}>Loading...</div>);

    if(this.state.leaderboard) {
      let lb = this.state.leaderboard;
      let entries = [];
      for(let i = 0; i < lb.users.length; i++) {
        let entry ={
          user: lb.users[i],
          value: lb.points[i]
        }

        switch(this.state.stat) {
          case 'season-points':
            entry.value = lb.points[i];
            break;
          case 'damage-dealt':
            entry.value = lb.damage.dealt[i];
            break;
          case 'damage-taken':
            entry.value = lb.damage.taken[i];
            break;
          case 'damage-shielded':
            entry.value = lb.damage.shielded[i];
            break;
          case 'boosts-used':
            entry.value = lb.used.boosts[i];
            break;
          case 'brakes-used':
            entry.value = lb.used.brakes[i];
            break;
          case 'rams-used':
            entry.value = lb.used.rams[i];
            break;
          case 'shields-used':
            entry.value = lb.used.shields[i];
            break;
          case 'most-dnf':
            entry.value = lb.dnf[i];
            break;
          case 'fastest-time':
            entry.value = lb.times[i]/1000;
            break;
        }

        entries.push(entry);
      }

      entries.sort((a, b)=>{
        if(this.state.stat == 'fastest-time') {
          if(a.value > b.value)
            return 1;
          else if(a.value < b.value)
            return -1;  
        }
        else {
          if(a.value > b.value)
            return -1;
          else if(a.value < b.value)
            return 1;
        }
        return 0;
      });

      for(let i = 0; i < entries.length; i++) {
        divs.push(
          <div key={i} className="flex-row" style={{justifyContent: 'space-between'}}>
            <div><span style={{color: 'gray'}}>{i+1}.</span> {entries[i].user}</div>
            <div>{entries[i].value}</div>
          </div>
        )
      }
    }

    return (
      <div>
          <select value={this.state.stat} onChange={(e:any)=>this.setState({stat: e.currentTarget.value})}>
            <option value='season-points'>Season Points</option>
            <option value='damage-dealt'>Damage Dealt</option>
            <option value='damage-taken'>Damage Taken</option>
            <option value='damage-shielded'>Damage Shielded</option>
            <option value='boosts-used'>Boosts Used</option>
            <option value='brakes-used'>Brakes Used</option>
            <option value='rams-used'>Rams Used</option>
            <option value='shields-used'>Shields Used</option>
            <option value='most-dnf'>Most DNF</option>
            <option value='fastest-time'>Fastest Time</option>
          </select>
          <div className="flex-column" style={{border: '1px solid #ffffff40', borderRadius: '10px', padding: '10px 10px 10px 5px'}}>
            {divs}
          </div>

      </div>

    )
  }

  renderProgressBar(current:number, max:number) {
    let barWidth = Math.floor((current/max) * 100) + '%';
    return (
      <div style={{width: '90px'}}>
        <div style={{position: 'relative', width: '100%', borderRadius: '4px', backgroundColor: 'var(--background-color)', height: '25px'}}>
            <div style={{position: 'absolute', top: '0px', left: '0px', width: barWidth, borderRadius: '4px', backgroundColor: 'green', height: '25px'}} />
            <div style={{position: 'absolute', top: '2px', left: '0px', fontSize: '0.9em', color: 'white', textAlign: 'center', width: '100%'}}>
              {current}
            </div>
        </div>
      </div>
    )
  }

  renderQuest(key:number, quest:any) {
    let button = null;
    if(quest.claimed) {
      button = (
        <div style={{fontSize: '1.2em', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'green', borderRadius: '50%', width: '35px', height: '35px'}}>
          <BsCheck />
        </div>
      )
    }
    else if(quest.progress >= quest.goal ) {
      button = <button onClick={()=>this.onClaimQuest(key)}>Claim</button>
    }
    else
    button = this.renderProgressBar(quest.progress, quest.goal);

    //   button = (
    //     <button style={{background: 'var(--panel-color', border: '1px solid #ffffff40'}}>
    //       {quest.progress}/{quest.goal}
    //     </button>
    // )

    let description = quest.description;

    if(description.indexOf('{car}') != -1) {
      let car = Database.getCarByServerId(quest.option);
      if(car)
        description = quest.description.replace('{car}', car.name);
    }

    if(description.indexOf('{driver}') != -1) {
      let driver = Database.getDriverByServerId(quest.option);
      if(driver)
        description = quest.description.replace('{driver}', driver.name);
    }

    let subtext = `${quest.reward} XP`

    return (
      <div key={key} className="flex-row" style={{justifyContent: 'space-between', border: '1px solid #ffffff20', borderRadius: '4px', padding: '5px 10px 5px 10px'}}>
        <div>
          {description}
          <div className="subtext">{subtext}</div>
        </div>
        {button}
      </div>
    )
  }

  renderQuests() {
    let quests = Account.getSeasonQuests();

    let completed = 0;
    for(let i = 0; i < quests.length; i++) 
      if(!quests[i].claimed && quests[i].progress >= quests[i].goal)
        completed++;

    let divs = [];
    for(let i = 0; i < quests.length; i++) 
      divs.push(this.renderQuest(i, quests[i]));

    let now = new Date();
    let midnight = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDay()))

    return (
      <div className="flex-column">
        <div style={{borderRadius: '6px', border: '1px solid #ffffff40', textAlign: 'center', color: 'orange', padding: '5px'}}>
          Daily quests reset at {midnight.toLocaleTimeString(navigator.language, {hour: 'numeric', minute:'2-digit', timeZoneName:'short'})}<br/>
          {completed > 0 && <div>Unclaimed quest rewards will be lost!</div>}
        </div>
        <div className="flex-column" style={{padding: '0px'}}>
          {divs}
        </div>
      </div>
    )
  }

  renderMilestone(key:number, milestone:any) {
    let button = null;
    if(milestone.complete) {
      button = (
        <div style={{fontSize: '1.2em', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'green', borderRadius: '50%', width: '35px', height: '35px'}}>
          <BsCheck />
        </div>
      )
    }
    else if(milestone.progress >= milestone.goal ) 
      button = <button onClick={()=>this.onClaimMilestone(key)}>Claim</button>
    else 
      button = this.renderProgressBar(milestone.progress, milestone.goal);

    let description = milestone.description.replace('{goal}', milestone.goal);
    let subtext = `Level ${Math.min(5, milestone.level+1)}`;
    if(!milestone.complete)
      subtext += ` - ${milestone.reward} XP`;

    return (
      <div key={key} className="flex-row" style={{justifyContent: 'space-between', border: '1px solid #ffffff20', borderRadius: '4px', padding: '5px 10px 5px 10px'}}>
        <div>
          {description}
          <div className="subtext">{subtext}</div>
        </div>
        {button}
      </div>
    )
  }

  renderMilestones() {
    let milestones = Account.getSeasonMilestones();

    let divs = [];
    for(let i = 0; i < milestones.length; i++) 
      divs.push(this.renderMilestone(i, milestones[i]));

    return (
      <div className="flex-column">
        <div style={{borderRadius: '6px', border: '1px solid #ffffff40', textAlign: 'center', color: 'orange', padding: '5px'}}>
          Milestones are active the entire season<br/>
          and have multiple levels of rewards.
        </div>
        <div className="flex-column" style={{padding: '0px'}}>
          {divs}
        </div>
      </div>
    )
  }

  renderRewards() {
    let rewards = Account.getSeasonRewards();
    let playerData = Account.getSeasonPlayerData();

    let divs = [];
    let page = Math.floor(playerData.level / 7);
    let maxRewards = Math.min(rewards.length, (page + 1) * 7);

    for(let i = 0; i < maxRewards; i++) {
      let asset = Database.getAssetByServerId(rewards[i].asset);

      if(!asset) {
        console.warn(`Unable to find asset ${rewards[i].asset}!`);
        continue;
      }

      let collection = Database.getCollection(asset.collection);

      let star = null;
      if(!rewards[i].free)
        star = <BsFillStarFill style={{color: 'yellow', fontSize: '0.7em'}} />

      let claim = null;
      if(rewards[i].claimed)
        claim = 
          <div className="flex-cell" style={{justifyContent: 'right'}}>
            <div className="flex-cell" style={{background: 'green', borderRadius: '50%', width: '40px', height: '40px'}}>
              <BsCheck style={{fontSize: '1.2em'}} />
            </div>
          </div>
      else if(i > playerData.level-1)
        claim = 
          <div className="flex-cell" style={{fontSize: '0.9em', color: 'gray', textAlign: 'center'}}>
            Reach Level {i+2}
          </div>
      else 
        claim = 
          <div className="flex-cell">
            <button style={{width: '100%'}} onClick={()=>this.onClaimSeasonReward(i)}>
              Claim
            </button>
          </div>

      let assetImage = `/images/assets/${asset.collection}/${asset.image ? asset.image : asset.id}.png`;

      let gridStyle = {
        display: 'grid', 
        gridTemplateColumns: '50px 1fr 70px', 
        columnGap: '10px', 
        padding: '5px 10px 5px 5px', 
        borderRadius: '4px', 
        border: '1px solid #ffffff20'
      };

      divs.push(
        <div key={i} style={gridStyle} id={'Reward' + i}>
          <img src={assetImage} style={{width: '50px', borderRadius: '4px'}} />
          <div>
            <div>{asset.name} {star}</div>
            <div className="subtext">{collection.asset}</div>
          </div>
          {claim}
        </div>
      )
    }

    if(rewards.length - maxRewards > 0) {
      divs.push(
        <div key={maxRewards+1} className="flex-cell" style={{border: '1px solid #ffffff40', padding: '5px', color: 'orange', borderRadius: '4px'}}>
          Reach level {maxRewards+1} to unlock more rewards.
        </div>
      )
    }

    return (
      <div className="flex-column">
        {playerData.level >= 7 && 
          <div className="flex-cell" style={{border: '1px solid #ffffff40', padding: '5px', color: 'orange', borderRadius: '4px', cursor: 'pointer'}} onClick={()=>this.onJumpToCurrentLevel()}>
            <BsCaretDownFill />&nbsp;&nbsp;Jump to current level&nbsp;&nbsp;<BsCaretDownFill />
          </div>
        }
        {divs}
        {divs.length > 14 && 
          <div className="flex-cell" style={{border: '1px solid #ffffff40', padding: '5px', color: 'orange', borderRadius: '4px', cursor: 'pointer'}} onClick={()=>this.onJumpToTop()}>
            <BsCaretUpFill />&nbsp;&nbsp;Jump to top&nbsp;&nbsp;<BsCaretUpFill />
          </div>
        }
      </div>

    )
  }

  renderStats() {
    let divs = [];

    if(this.state.loading) 
      divs.push(<div key={0}>Loading...</div>);

    if(this.state.leaderboard) {
      let lb = this.state.leaderboard;
      let entries = [];
      for(let i = 0; i < lb.users.length; i++) {
        let entry ={
          user: lb.users[i],
          value: lb.points[i]
        }

        switch(this.state.stat) {
          case 'season-points':
            entry.value = lb.points[i];
            break;
          case 'damage-dealt':
            entry.value = lb.damage.dealt[i];
            break;
          case 'damage-taken':
            entry.value = lb.damage.taken[i];
            break;
          case 'damage-shielded':
            entry.value = lb.damage.shielded[i];
            break;
          case 'boosts-used':
            entry.value = lb.used.boosts[i];
            break;
          case 'brakes-used':
            entry.value = lb.used.brakes[i];
            break;
          case 'rams-used':
            entry.value = lb.used.rams[i];
            break;
          case 'shields-used':
            entry.value = lb.used.shields[i];
            break;
          case 'most-dnf':
            entry.value = lb.dnf[i];
            break;
          case 'fastest-time':
            entry.value = lb.times[i]/1000;
            break;
        }

        entries.push(entry);
      }

      entries.sort((a, b)=>{
        if(this.state.stat == 'fastest-time') {
          if(a.value > b.value)
            return 1;
          else if(a.value < b.value)
            return -1;  
        }
        else {
          if(a.value > b.value)
            return -1;
          else if(a.value < b.value)
            return 1;
        }
        return 0;
      });

      for(let i = 0; i < entries.length; i++) {
        divs.push(
          <div key={i} className="flex-row" style={{justifyContent: 'space-between'}}>
            <div><span style={{color: 'gray'}}>{i+1}.</span> {entries[i].user}</div>
            <div>{entries[i].value}</div>
          </div>
        )
      }
    }

    return (
      <div className="flex-column">
        <select value={this.state.stat} style={{width: '100%'}} onChange={(e:any)=>this.setState({stat: e.currentTarget.value})}>
          <option value='season-points'>Season Points</option>
          <option value='damage-dealt'>Damage Dealt</option>
          <option value='damage-taken'>Damage Taken</option>
          <option value='damage-shielded'>Damage Shielded</option>
          <option value='boosts-used'>Boosts Used</option>
          <option value='brakes-used'>Brakes Used</option>
          <option value='rams-used'>Rams Used</option>
          <option value='shields-used'>Shields Used</option>
          <option value='most-dnf'>Most DNF</option>
          <option value='fastest-time'>Fastest Time</option>
        </select>
        <div className="flex-column" style={{border: '1px solid #ffffff40', borderRadius: '10px', padding: '10px 10px 10px 5px'}}>
          {divs}
        </div>
      </div>
    )
  }

  renderMessageDialog() {
    return (
      <div className="dialog-cover">
        <div className="dialog-panel" style={{maxWidth: '250px', padding: '20px'}}>
          {this.state.message}
        </div>
      </div>
    )
  }

  render() {
    if(!Account.isDeveloperLoggedIn())
      return <Navigate to="/" replace />

    let playerData = Account.getSeasonPlayerData();
    let level = playerData.level;
    let currentLevel = playerData.current;
    let nextLevel = playerData.max;
    let levelPercent = (currentLevel / nextLevel) * 100;

    let now = new Date();
    let seasonEndDate = new Date();
    seasonEndDate.setUTCFullYear(2024);
    seasonEndDate.setUTCMonth(6);
    seasonEndDate.setUTCDate(31);
    seasonEndDate.setUTCHours(12);
    let hours = Math.floor((seasonEndDate.getTime() - now.getTime()) / 1000 / 60 / 60);
    let days = Math.floor(hours/24);
    hours = Math.floor(hours - (days * 24));

    let questClass = this.state.tab == 'quests' ? 'season-page-tab-active' : 'season-page-tab';
    let milestonesClass = this.state.tab == 'milestones' ? 'season-page-tab-active' : 'season-page-tab';
    let rewardsClass = this.state.tab == 'rewards' ? 'season-page-tab-active' : 'season-page-tab';
    let statsClass = this.state.tab == 'stats' ? 'season-page-tab-active' : 'season-page-tab';

    const { playerProfile } = this.state;
    const avatarUrl = playerProfile?.AvatarUrl || 'path/to/default/avatar.png';
    const displayName = playerProfile?.DisplayName || Account.getUserName();

    console.log('SeasonPage: User avatar URL:', avatarUrl);

    return (
      <div className="page">
        <div className="flex-column" style={{maxWidth: '500px', margin: 'auto'}}>

          <div className="panel">
            <div className="flex-row" style={{alignItems: 'normal', columnGap: '15px', paddingRight: '5px'}}>
              <img style={{borderRadius: '50%', width: '100px'}} src={avatarUrl} />
              <div className="flex-column" style={{width: '100%', rowGap: '6px'}}>
                <div style={{fontSize: '1.1em'}}>{displayName}</div>
                <div>
                  <div className="subtext" style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>Level {level+1}</div>
                    <div>{currentLevel}/{nextLevel} XP</div>
                  </div>
                  <div style={{marginTop: '2px', backgroundColor: 'var(--background-color)', borderRadius: '4px', height: '16px'}}>
                    <div style={{backgroundColor: 'orange', borderRadius: '4px', height: '16px', width: levelPercent + '%'}} />
                  </div>
                </div>
                <div className="subtext">Season 2 ends in {days}d {hours}h</div>
              </div>
            </div>
          </div>
          <div className="panel">
            <div style={{display: 'grid', gridTemplateColumns: '23% 30% 27% 20%', columnGap: '0px'}}>
              <div className={questClass} onClick={()=>this.onTab('quests')}>Quests</div>
              <div className={milestonesClass} onClick={()=>this.onTab('milestones')}>Milestones</div>
              <div className={rewardsClass} onClick={()=>this.onTab('rewards')}>Rewards</div>
              <div className={statsClass} onClick={()=>this.onTab('stats')}>Stats</div>
            </div>
          </div>
          <div className="panel" style={{padding: '10px'}}>
            {this.state.tab == 'quests' && this.renderQuests()}
            {this.state.tab == 'milestones' && this.renderMilestones()}
            {this.state.tab == 'rewards' && this.renderRewards()}
            {this.state.tab == 'stats' && this.renderStats()}
          </div>
        </div>
        {this.state.message != '' && this.renderMessageDialog()}
      </div>
    )
  }
}

export default SeasonPage;
