import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface Auth0PageProps {
  onUpdate?: () => void;
}

function Auth0Page({ onUpdate }: Auth0PageProps) {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Auth0Page: Processing login");
    
    // Get the return URL from sessionStorage, or default to '/'
    const returnTo = sessionStorage.getItem('loginReturnUrl') || '/';
    console.log(`Auth0Page: Redirecting to ${returnTo}`);
    
    // Clear the stored return URL
    sessionStorage.removeItem('loginReturnUrl');
    
    // Call onUpdate if provided
    if (onUpdate) {
      onUpdate();
    }
    
    // Redirect to the return URL
    navigate(returnTo, { replace: true });
  }, [navigate, onUpdate]);

  return (
    <div className="page">
      <div className="dialog-cover">
        <div className="dialog-panel" style={{maxWidth: '250px', padding: '20px'}}>
          Completing login, please wait...
        </div>
      </div>
    </div>
  );
}

export default Auth0Page;